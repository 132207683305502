import Logo from '@components/common/logo'
import { t } from '@lingui/macro'
import dynamic from 'next/dynamic'
import { Dispatch, SetStateAction } from 'react'
import { X } from 'react-feather'

const LoggedInMobileItems = dynamic(() =>
  import('@components/common/navbar/logged-in-mobile-items')
)
const LoggedOutMobileItems = dynamic(() =>
  import('@components/common/navbar/logged-out-mobile-items')
)

type Props = {
  showMobileMenu: boolean
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>
  loggedIn: boolean
  isQuizChampionship: any
  customCTAText: string
  customCTAonClick: () => void
  next: string
  isPaidUser: boolean
  trialClassCompleteLink: string
  username: string
  teacher: any
  tryFreeClassLink?: string
}

const MobileNavbar = ({
  setShowMobileMenu,
  showMobileMenu,
  isQuizChampionship,
  loggedIn,
  customCTAText,
  customCTAonClick,
  isPaidUser,
  trialClassCompleteLink,
  username,
  next,
  teacher,
  tryFreeClassLink,
}: Props) => {
  const hideMobileMenu = () => {
    document.body.classList.remove('overflow-y-hidden', 'md:overflow-y-auto')
    setShowMobileMenu(false)
  }

  return (
    <div
      className={`z-20 fixed bg-white top-0 inset-x-0 transition-all transform lg:hidden overflow-x-hidden duration-500 ease-in-out ${
        showMobileMenu ? 'max-h-full' : 'max-h-0'
      }`}
    >
      <div className="h-screen bg-white">
        <div className="px-5 space-y-10">
          <div className="flex items-center justify-between h-16">
            <div>
              <Logo
                withText
                alt={t`Codingal`}
                className="h-8 w-auto"
                height={32}
                width={120}
              />
            </div>
            <button
              type="button"
              aria-label="Close"
              className={`focus:outline-none transform transition duration-500 ease-in ${
                showMobileMenu ? 'scale-100' : 'scale-0'
              }`}
              onClick={hideMobileMenu}
            >
              <X className="text-grey" />
            </button>
          </div>
          {!loggedIn && (
            <LoggedOutMobileItems
              isQuizChampionship={isQuizChampionship}
              hideMobileMenu={hideMobileMenu}
              customCTAText={customCTAText}
              customCTAonClick={customCTAonClick}
              nextPage={next}
              tryFreeClassLink={tryFreeClassLink}
            />
          )}

          {loggedIn && (
            <LoggedInMobileItems
              customCTAText={customCTAText}
              customCTAonClick={customCTAonClick}
              isPaidUser={isPaidUser}
              isQuizChampionship={isQuizChampionship}
              teacher={teacher}
              trialClassCompleteLink={trialClassCompleteLink}
              username={username}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileNavbar
